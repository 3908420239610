import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { rhythm } from "../../utils/typography"

class MainIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`About Wilson Mitchell`}
          keywords={[`Japanese`, `travel`, `personal development`, `Korean`, `blog`, `gatsby`, `javascript`, `react`]}
        />
        <h2 style={{ marginTop: rhythm(1.0) }}>About Me</h2>
        <Bio />
        <p>
          Hi, this is Wilson Mitchell.
          I'm a software developer in Tokyo, Japan at the moment.
          Previously, I went to Virginia Tech for 2 degrees in Mathematics and Computer Science.
          During that time, I was a big part of the <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/MAD-Virginia-Tech-114378021970121/">Mobile Application Development Club</a>, serving as the president for a while, and helping with the first student-run hackathon <a target="_blank" rel="noopener noreferrer" href="https://vthacks.com">VTHacks</a>.
          I also interned at The Washington Post and Amazon twice.
          After graduating, I moved out to Seattle to work for Amazon Fresh.
        </p>
        <p>
          My passion is for creating new experiences and connecting with and helping others.
          One way I get to do this is with the apps I have put out on the Android (Google Play as well as Amazon Appstore), iOS (mostly cross-platform games using <a target="_blank" rel="noopener noreferrer" href="https://libgdx.badlogicgames.com">LibGDX</a>, no longer paying for Developer license), and Windows Phone stores.
          {' '}<a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.nog.floppyfish1">Floppy Fish</a>, <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.hackathon.packagepopper">Package Popper</a>, the <a target="_blank" rel="noopener noreferrer" href="https://github.com/mitchellw/VTHacks-Android">VTHacks Android app</a>, a Blacksburg Transit app, Expression Guessin' (Windows Phone game), and Planetary Weight (won a 2 hour Windows Phone hackathon at VT) are ones I have created or worked on and actually put on the app store.
        </p>
        <p>
          I was also lucky enough to give a <a target="_blank" rel="noopener noreferrer" href="https://github.com/mitchellw/AndroidWorkshop">workshop</a> on how to create Android apps during my last year at Virginia Tech.
          I particularly like holding workshops and giving tech talks and have been looking into doing it more often, so let me know if there are any events you think might be interesting to present at.
          I am especially looking at giving a talk in Japanese sometime soon.
        </p>
        <p>
          Outside of work and tech, I like language learning, travel, playing and creating board games, and skateboarding.
          I run a game night at Amazon and a <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TokyoBGLX">language exchange</a> in Meguro where we use board games as a common topic to spur conversation and break the ice.
          I find that cooperative games like Pandemic and The Forbidden Island/Desert/Sky to be particularly fun and lead to a lot of learning of how to clearly express complex plans/ideas and build agreement with others.
        </p>
        <p>
          Read more about what motivates me <Link to={`/blog/mission-and-principles`}>here</Link>
        </p>
      </Layout>
    )
  }
}

export default MainIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
